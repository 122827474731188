import dayjs from "dayjs";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import useGetUsers from "hooks/api/useGetUsers";
import { useDebounce } from "hooks/useDebounce";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import {
  BillingSort,
  BillingSortColumn,
  convertSortColumnToName,
  findBillingSortColumnAndDirection,
  initialBillingSort,
  update,
} from "pages/Billing/pc/billingSortColumn";
import { findUniqueDepartments } from "utils/accountUtils";
import { rangeOfYear } from "utils/dateUtils";

// 利用年の表示は、2023年から現在から半年後の年まで
const yearsOfUse = [...rangeOfYear("2023", dayjs().add(6, "month"))];

/**
 * BillingContainerの共通ロジック（state・関数）をまとめたカスタムフック
 * @returns BillingContainerの共通ロジック（state・関数）
 */
const useBillingCommonContainer = () => {
  // 検索キーワード
  const [searchKeyword, setSearchKeyword] = useState("");
  const { debouncedValue } = useDebounce(searchKeyword, 500);

  // ページネーション
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

  // アカウント・部署選択
  const [chosenAccount, setChosenAccount] = useState<Account>({ id: "ALL", name: "すべて", department: "すべて" });
  const [chosenDepartment, setChosenDepartment] = useState<string>("すべて");
  const { accountList } = useGetUsers();
  const departments = useMemo(() => findUniqueDepartments(accountList), [accountList]);

  // 利用年・月選択
  const queryParams = new URLSearchParams(window.location.search);
  const [billingYear, setBillingYear] = useState<string>(queryParams.get("year") ?? dayjs().format("YYYY"));
  const [billingMonth, setBillingMonth] = useState<MonthWithCount>({
    month: queryParams.get("month") ?? `${dayjs().month() + 1}`,
    countResult: 0,
  });

  // ソート
  const { sendEvent } = useGoogleAnalytics(true);
  const [billingSort, setBillingSort] = useState<BillingSort>(initialBillingSort);
  const updateBillingSort = (column: BillingSortColumn, previous: BillingSort): void => {
    setBillingSort(update(column, previous));
    sendEvent({
      action: `請求_${convertSortColumnToName(column)}_ソート`,
      category: "sort",
      label: "/billing",
    });
  };
  const onClickSort = (column: BillingSortColumn, previous: BillingSort) => updateBillingSort(column, previous);
  const { sortColumn, sortDirection } = findBillingSortColumnAndDirection(billingSort);

  // 利用詳細への遷移
  const navigate = useNavigate();
  const toUsageDetail = useCallback(
    (contractId: string) => {
      sendEvent({
        action: "請求画面から利用詳細画面に遷移",
        category: "other",
        label: "/billing",
      });
      navigate("/usage/" + contractId);
    },
    [navigate, sendEvent]
  );

  return {
    yearsOfUse,
    searchKeyword,
    setSearchKeyword,
    debouncedValue,
    currentPageNumber,
    setCurrentPageNumber,
    chosenAccount,
    setChosenAccount,
    chosenDepartment,
    setChosenDepartment,
    accountList,
    departments,
    billingYear,
    setBillingYear,
    billingMonth,
    setBillingMonth,
    billingSort,
    setBillingSort,
    onClickSort,
    sortColumn,
    sortDirection,
    toUsageDetail,
  };
};

export default useBillingCommonContainer;
