import { Suspense, lazy, useEffect, useState } from "react";
import { isBrowser, isTablet } from "react-device-detect";

import Loading from "components/Loading";
import useFirebaseAuth from "hooks/useFirebaseAuth";
import { useNoticeModal } from "hooks/useModal";

const MfaRevertCompletePresenter = lazy(() =>
  isBrowser
    ? import("pages/MfaRevert/pc/MfaRevertPresenter")
    : isTablet
    ? import("pages/MfaRevert/tb/MfaRevertPresenter")
    : import("pages/MfaRevert/sp/MfaRevertPresenter")
);
const MfaRevertCompleteContainer = () => {
  const { revertSecondFactorAddition, sendPasswordResetEmail } = useFirebaseAuth();
  const { NoticeModal, showNoticeModal, hideNoticeModal } = useNoticeModal();

  const [phoneInfo, setPhonInfo] = useState<string>("");
  const [email, setEmail] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    const revertMFA = async () => {
      const apiKey = new URLSearchParams(window.location.search).get("apiKey");
      const oobCode = new URLSearchParams(window.location.search).get("oobCode");

      if (!apiKey || !oobCode) {
        console.error("invalid query");
        return;
      }
      console.log("revert");
      const { phoneInfo, email } = await revertSecondFactorAddition(apiKey, oobCode);
      setPhonInfo(phoneInfo);
      setEmail(email);
    };

    (async () =>
      await revertMFA().catch((e) => {
        console.error(e);
        setErrorMessage(e.message);
      }))();

    // 初回のみ実行させたいため,ESLintのwarningを無効化している
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendPasswordReset = async () => {
    if (!email) return;

    const result = await sendPasswordResetEmail(email).catch((e) => {
      setErrorMessage(e.message);
    });

    if (result) showNoticeModal();
  };

  return (
    <Suspense fallback={<Loading />}>
      {(phoneInfo || errorMessage) && (
        <MfaRevertCompletePresenter phoneInfo={phoneInfo} errorMessage={errorMessage} onClick={sendPasswordReset} />
      )}

      <NoticeModal
        title="再設定用URLをお送りしました"
        message={
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <span>メールのURLから</span>
            <span>パスワードを再設定してください</span>
          </div>
        }
        onClick={hideNoticeModal}
      />
    </Suspense>
  );
};

export default MfaRevertCompleteContainer;
