import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

import { API_URL } from "constants/api";

const useUnauthorizedClient = () => {
  const unauthorizedClient = async <T, D>(config: AxiosRequestConfig): Promise<AxiosResponse<T, D>> => {
    const response = await axios
      .request<T>({
        method: config.method,
        url: `${API_URL}${config.url}`,
        headers: {
          accept: "application/json",
        },
        params: config.params ?? undefined,
        data: config.data ?? undefined,
      })
      .catch((e) => {
        throw e;
      });

    return response;
  };
  return { unauthorizedClient };
};

export default useUnauthorizedClient;
