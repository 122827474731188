import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";

const useFindContractsByOpportunityId = (
  orderId: string
): {
  contracts: ContractDetail[];
  error: Error;
  mutate: KeyedMutator<ContractDetail[]>;
} => {
  const { data, error, mutate } = useSwr<ContractDetail[]>(`${API_URL}/order/${orderId}/contract`);

  return { contracts: data, error, mutate };
};

export default useFindContractsByOpportunityId;
