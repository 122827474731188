import { isBrowser, isTablet } from "react-device-detect";
import styled from "styled-components";

import color from "constants/color";
import Footer from "templates/Footer";

// パーツ定義
const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  ${!isBrowser && !isTablet && `margin: 0; background-color: ${color.white};`}
`;

// 本体
const UnLoggedLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <Wrapper>
      <Main>{children}</Main>
      <Footer />
    </Wrapper>
  );
};

export default UnLoggedLayout;
