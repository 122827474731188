import { useEffect } from "react";
import { isBrowser, isMobile, isTablet } from "react-device-detect";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import Footer from "templates/Footer";
import Header from "templates/Header";
import HeaderForSp from "templates/HeaderForSp";
import { HeaderForTb } from "templates/HeaderForTb";

// パーツ定義
const Wrapper = styled.div`
  display: grid;
  min-height: 100vh;
  grid-template-rows: auto 1fr auto;
`;

const Main = styled.main<{ isBrowser?: boolean }>`
  margin-bottom: ${({ isBrowser = true }) => (isBrowser ? 50 : 0)}px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex: 1 1 auto;
`;

// 本体
const Layout = ({ children }: { children: JSX.Element[] }) => {
  const location = useLocation();

  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
    <Wrapper>
      {isBrowser ? <Header /> : isTablet ? <HeaderForTb /> : <HeaderForSp />}
      <Main isBrowser={isBrowser}>{children}</Main>
      <Footer />
    </Wrapper>
  );
};

export default Layout;
