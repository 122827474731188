import { isBrowser, isTablet } from "react-device-detect";
import styled from "styled-components";

import Button from "components/Button";
import Modal from "components/Modal";
import font from "constants/font";

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Title = styled.span`
  font-size: ${font.size20};
`;
const ActionArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
`;

const InfoModal = ({
  isVisible,
  onClose,
  title,
  onClick,
}: {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  onClick?: () => Promise<void> | void;
}) => {
  return (
    <Modal isVisible={isVisible} onClose={onClose} paddingHorizontal={!isBrowser && !isTablet ? 16 : 0}>
      <>
        <Body>
          <Title>{title}</Title>
        </Body>
        <ActionArea>
          <Button
            label="閉じる"
            onClick={() => {
              onClose();
              onClick && onClick();
            }}
            type="secondary"
            width="160px"
          />
        </ActionArea>
      </>
    </Modal>
  );
};

export default InfoModal;
