import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";

const useGetUserMfa = (): {
  userMfaInfo: UserMfaInfo;
  error: Error;
  mutate: KeyedMutator<UserMfaInfo>;
} => {
  const { data, error, mutate } = useSwr<UserMfaInfo>(`${API_URL}/user/mfa`, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return { userMfaInfo: data, error, mutate };
};

export default useGetUserMfa;
