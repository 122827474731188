import queryString from "query-string";
import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";
import useUserContext from "hooks/useUserContext";

export type FindOptionsParams = {
  pageSize: number;
  pageNumber: number;
  orderType: "asc" | "desc";
  orderBy?: "createdAt";
  contactId?: string;
  department?: string;
  searchKeyword?: string;
};
const initialPagination = {
  pageSize: 100,
  pageIndex: 1,
  totalPages: 1,
  totalRecords: 100,
};

const useFindOptions = (
  params: FindOptionsParams
): {
  options: Option[] | undefined;
  pagination: Pagination;
  error: Error;
  mutate: KeyedMutator<OptionResponse>;
} => {
  const { user } = useUserContext();
  const { data, error, mutate } = useSwr<OptionResponse>(
    `${API_URL}/option?${queryString.stringify({ ...params, billingUnit: user.billingUnit })}`,
    {
      revalidateIfStale: true,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  if (!data || error)
    return {
      options: undefined,
      pagination: initialPagination,
      error,
      mutate,
    };

  return { options: data.data, pagination: data.pagination, error, mutate };
};

export default useFindOptions;
