export const UsageStatusFilter = {
  all: "all",
  beforeUse: "beforeUse",
  duringUse: "duringUse",
  endOfUse: "endOfUse",
} as const;
export type UsageStatusFilter = typeof UsageStatusFilter[keyof typeof UsageStatusFilter];

export const UsageStatus = {
  all: "all",
  beforeUse: "beforeUse",
  duringUse: "duringUse",
  endOfUse: "endOfUse",
} as const;
export type UsageStatus = typeof UsageStatus[keyof typeof UsageStatus];

export const UsageStatusLabel = {
  [UsageStatusFilter.all]: "すべて",
  [UsageStatus.beforeUse]: "利用前",
  [UsageStatus.duringUse]: "利用中",
  [UsageStatus.endOfUse]: "利用終了",
} as const;
