import { reverse, sortDirection, SortDirection } from "constants/sortDirection";

export const BillingSortColumn = {
  NONE: "NONE",
  PARKING_ADDRESS: "PARKING_ADDRESS",
  BILLING_PERIOD: "BILLING_PERIOD",
  TOTAL_RENT: "TOTAL_RENT",
  BILLING_DATE: "BILLING_DATE",
  PAYMENT_DATE: "PAYMENT_DATE",
  ACCOUNT_NAME: "ACCOUNT_NAME",
} as const;
export type BillingSortColumn = typeof BillingSortColumn[keyof typeof BillingSortColumn];

export const convertSortColumnToName = (sortColumn: BillingSortColumn): string => {
  switch (sortColumn) {
    case BillingSortColumn.PARKING_ADDRESS:
      return "駐車場名";
    case BillingSortColumn.BILLING_PERIOD:
      return "利用期間";
    case BillingSortColumn.TOTAL_RENT:
      return "利用料金";
    case BillingSortColumn.BILLING_DATE:
      return "請求日";
    case BillingSortColumn.PAYMENT_DATE:
      return "支払期日";
    case BillingSortColumn.ACCOUNT_NAME:
      return "担当";
    default:
      return "";
  }
};

export type BillingSortColumnAndDirection = {
  sortColumn: BillingSortColumn;
  sortDirection: SortDirection;
};

export type BillingSort = {
  parkingAddressSort?: SortDirection;
  billingPeriodSort?: SortDirection;
  totalRentSort?: SortDirection;
  billingDateSort?: SortDirection;
  paymentDateSort?: SortDirection;
  accountNameSort?: SortDirection;
};

export const initialBillingSort: BillingSort = {};

export const update = (sortColumn: BillingSortColumn, previous: BillingSort): BillingSort => {
  switch (sortColumn) {
    case BillingSortColumn.PARKING_ADDRESS:
      return { ...initialBillingSort, parkingAddressSort: reverse(previous.parkingAddressSort) };
    case BillingSortColumn.BILLING_PERIOD:
      return { ...initialBillingSort, billingPeriodSort: reverse(previous.billingPeriodSort) };
    case BillingSortColumn.TOTAL_RENT:
      return { ...initialBillingSort, totalRentSort: reverse(previous.totalRentSort) };
    case BillingSortColumn.BILLING_DATE:
      return { ...initialBillingSort, billingDateSort: reverse(previous.billingDateSort) };
    case BillingSortColumn.PAYMENT_DATE:
      return { ...initialBillingSort, paymentDateSort: reverse(previous.paymentDateSort) };
    case BillingSortColumn.ACCOUNT_NAME:
      return { ...initialBillingSort, accountNameSort: reverse(previous.accountNameSort) };
    default:
      return initialBillingSort;
  }
};

export const findBillingSortColumnAndDirection = (billingSort: BillingSort): BillingSortColumnAndDirection => {
  return billingSort.parkingAddressSort
    ? { sortColumn: BillingSortColumn.PARKING_ADDRESS, sortDirection: billingSort.parkingAddressSort }
    : billingSort.billingPeriodSort
    ? { sortColumn: BillingSortColumn.BILLING_PERIOD, sortDirection: billingSort.billingPeriodSort }
    : billingSort.totalRentSort
    ? { sortColumn: BillingSortColumn.TOTAL_RENT, sortDirection: billingSort.totalRentSort }
    : billingSort.billingDateSort
    ? { sortColumn: BillingSortColumn.BILLING_DATE, sortDirection: billingSort.billingDateSort }
    : billingSort.paymentDateSort
    ? { sortColumn: BillingSortColumn.PAYMENT_DATE, sortDirection: billingSort.paymentDateSort }
    : billingSort.accountNameSort
    ? { sortColumn: BillingSortColumn.ACCOUNT_NAME, sortDirection: billingSort.accountNameSort }
    : { sortColumn: BillingSortColumn.NONE, sortDirection: sortDirection.desc };
};
