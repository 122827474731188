import React from "react";

type State = {
  user: User;
  setUser: React.Dispatch<React.SetStateAction<User>>;
  isUserLoaded: boolean;
  isMonthlyBillingAccount: boolean;
};

const UserContext = React.createContext({} as State);

export default UserContext;
