import dayjs from "dayjs";

const dayjsUnit = {
  day: "day",
  week: "week",
  month: "month",
  quarter: "quarter",
  year: "year",
  hour: "hour",
  minute: "minute",
  second: "second",
  millisecond: "millisecond",
} as const;

/**
 * 日付をフォーマットする
 * @param value
 * @param format
 * @returns
 */
export const dateFormat = (value: string | number, format = "YYYY/MM/DD") => {
  return dayjs(value).format(format);
};

/**
 * 開始日から終了日までの日数を返す
 * @param start
 * @param end
 * @returns \{month: number, day: number}
 */
export const periodDate = (
  start: string | dayjs.Dayjs,
  end: string | dayjs.Dayjs
): {
  month: number;
  day: number;
} => {
  const diffDays = dayjs(end).diff(dayjs(start), dayjsUnit.day);
  return { month: Math.floor(diffDays / 30), day: diffDays % 30 };
};

/**
 * startからendまでの年を最新年順で返す
 * @param start
 * @param end
 * Usage: [...rangeOfYear('2022', '2024')] -> ['2024', '2023', '2022']
 */
export function* rangeOfYear(start: string | dayjs.Dayjs, end: string | dayjs.Dayjs): Generator<string> {
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  for (let date = endDate; startDate <= date; date = date.subtract(1, "year")) {
    yield date.format("YYYY");
  }
}

/**
 * 年・月・日を統合して返す
 * @param year 年
 * @param month 月
 * @param day 日
 * @returns "YYYY/MM/DD"
 */
export const buildDate = (year: string | number, month: string | number, day: string | number): string => {
  return dateFormat(`${year}-${month}-${day}`);
};
