import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";

const useGetUsers = (): {
  accountList: Account[];
  error: Error;
  mutate: KeyedMutator<Account[]>;
} => {
  const { data, error, mutate } = useSwr<Account[]>(`${API_URL}/user/account/list`);

  if (!data) return { accountList: [], error, mutate };

  return { accountList: [{ id: "ALL", name: "すべて", department: "すべて" }, ...data], error, mutate };
};

export default useGetUsers;
