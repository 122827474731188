const font = {
  size50: "50px",
  size38: "38px",
  size30: "30px",
  size24: "24px",
  size20: "20px",
  size16: "16px",
  size14: "14px",
  size12: "12px",
  size10: "10px",
};

export default font;
