export type Department = {
  label: string;
  key: string;
  value: string;
};

export const findUniqueDepartments = (accounts: Account[]) => {
  const departments: Department[] = [];
  if (accounts && accounts.length > 0) {
    // 取得した全アカウントの部署を抽出、Setで重複を排除、空文字列をfilterで除去、選択肢に追加
    [...new Set(accounts.map((a) => a.department))]
      .filter((a) => a)
      .forEach((a) => departments.push({ label: a, key: a, value: a }));
  }
  return departments;
};
