import { useCallback } from "react";

import useClient from "hooks/useClient";

const useRejectEstimate = (): {
  rejectEstimate: (orderId: string, estimateId: string, rejectReason: RejectReason) => Promise<number>;
} => {
  const { client } = useClient();

  const rejectEstimate = useCallback(
    async (orderId: string, estimateId: string, rejectReason: RejectReason) => {
      const { status } = await client({
        method: "patch",
        url: `/order/${orderId}/estimate/${estimateId}/reject`,
        data: rejectReason,
      });
      return status;
    },
    [client]
  );

  return { rejectEstimate };
};

export default useRejectEstimate;
