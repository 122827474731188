import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import Button from "components/Button";
import color from "constants/color";

const Wrap = styled.div``;

const PopupWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 60px;
  left: 0;
  display: flex;
  width: 100vw;
  z-index: 10;
`;

const ButtonsWrap = styled.div`
  display: flex;
  width: 95%;
  border-radius: 4px;
  background-color: ${color.white};
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.15);
  padding: 16px 0;
`;

const FillWrap = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 24px;
  padding: 0 24px;
`;

const Triangle = styled.div`
  position: absolute;
  z-index: 10;
  top: -8px;
  right: 114px;
  border-right: 5px solid transparent;
  border-bottom: 8.6025px solid ${color.white};
  border-left: 5px solid transparent;
  transform-origin: center;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: scale(2.4);
`;

const PopupButtons = ({
  introMessage,
  introWidth,
  buttons,
}: {
  introMessage: string;
  introWidth: string;
  buttons: {
    text: string;
    onClick: () => void;
    type?: "primary" | "secondary";
  }[];
}) => {
  const [visible, setVisible] = useState(false);

  const wrapRef = useRef<HTMLDivElement>(null);

  const handleCloseAfterAction = (action: () => void) => {
    action();
    setVisible(false);
  };

  // 外側クリックでポップアップを閉じる
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapRef.current && !wrapRef.current.contains(event.target as Node)) {
        setVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Wrap ref={wrapRef}>
      <Button
        label={visible ? "閉じる" : introMessage}
        width={introWidth}
        height="100%"
        onClick={() => setVisible((prev) => !prev)}
        type={visible ? "secondary" : "primary"}
      />
      {visible && (
        <PopupWrap>
          <Triangle />
          <ButtonsWrap>
            <FillWrap>
              {buttons.map((button) => (
                <Button
                  key={button.text}
                  label={button.text}
                  onClick={() => handleCloseAfterAction(button.onClick)}
                  type={button.type}
                  width={"100%"}
                />
              ))}
            </FillWrap>
          </ButtonsWrap>
        </PopupWrap>
      )}
    </Wrap>
  );
};

export default PopupButtons;
