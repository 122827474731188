import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled, { keyframes } from "styled-components";

const motion = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoadingIcon = styled(FontAwesomeIcon)`
  animation: ${motion} 1.2s linear infinite;
`;

const Loading = () => {
  return <LoadingIcon icon={faSpinner} />;
};

export default Loading;
