import { useCallback, useEffect } from "react";
import ReactGA from "react-ga4";
import { useLocation } from "react-router-dom";

const MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID;
if (MEASUREMENT_ID != null) {
  ReactGA.initialize(MEASUREMENT_ID);
}

export const useGoogleAnalytics = (preventPageViewTracker?: boolean) => {
  const location = useLocation();

  useEffect(() => {
    // App.tsx以外でフックが呼ばれた場合は、pageViewを無効にする
    if (MEASUREMENT_ID && !preventPageViewTracker) {
      // 18桁のIDを“objectID”に置換する
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname.replace(/\/\w{18}($|\/)/, "/detail$1"),
      });
    }
  }, [location.pathname, preventPageViewTracker]);

  /**
   * GA4イベントを送信する
   * @param action イベント内容
   * @param category イベントのカテゴリ 例）button, link, other ...
   * @param label イベント発生箇所のPATH
   */
  const sendEvent = useCallback(({ action, category, label }: { action: string; category: string; label?: string }) => {
    if (MEASUREMENT_ID == null) return;

    ReactGA.event({
      action,
      category,
      label,
    });
  }, []);

  return { sendEvent };
};
