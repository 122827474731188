import { reverse, sortDirection, SortDirection } from "constants/sortDirection";

export const UsageSortColumn = {
  NONE: "NONE",
  USAGE_STATUS: "USAGE_STATUS",
  PARKING_ADDRESS: "PARKING_ADDRESS",
  USAGE_PERIOD: "USAGE_PERIOD",
  ACCOUNT_NAME: "ACCOUNT_NAME",
} as const;
export type UsageSortColumn = typeof UsageSortColumn[keyof typeof UsageSortColumn];

type UsageSortColumnAndDirection = {
  sortColumn: UsageSortColumn;
  sortDirection: SortDirection;
};

export const convertUsageSortColumnToName = (sortColumn: UsageSortColumn): string => {
  switch (sortColumn) {
    case UsageSortColumn.USAGE_STATUS:
      return "利用状況";
    case UsageSortColumn.PARKING_ADDRESS:
      return "駐車場住所";
    case UsageSortColumn.USAGE_PERIOD:
      return "利用期間";
    case UsageSortColumn.ACCOUNT_NAME:
      return "担当";
    default:
      return "";
  }
};

export type UsageSort = {
  usageStatus?: SortDirection;
  constructionNameSort?: SortDirection;
  parkingAddressSort?: SortDirection;
  usagePeriodSort?: SortDirection;
  accountNameSort?: SortDirection;
};

export const initialUsageSort: UsageSort = {};

export const update = (sortColumn: UsageSortColumn, previous: UsageSort): UsageSort => {
  switch (sortColumn) {
    case UsageSortColumn.USAGE_STATUS:
      return { ...initialUsageSort, usageStatus: reverse(previous.usageStatus) };
    case UsageSortColumn.PARKING_ADDRESS:
      return { ...initialUsageSort, parkingAddressSort: reverse(previous.parkingAddressSort) };
    case UsageSortColumn.USAGE_PERIOD:
      return { ...initialUsageSort, usagePeriodSort: reverse(previous.usagePeriodSort) };
    case UsageSortColumn.ACCOUNT_NAME:
      return { ...initialUsageSort, accountNameSort: reverse(previous.accountNameSort) };
    default:
      return initialUsageSort;
  }
};

export const findUsageSortColumnAndDirection = (usageSort: UsageSort): UsageSortColumnAndDirection => {
  return usageSort.usageStatus
    ? { sortColumn: UsageSortColumn.USAGE_STATUS, sortDirection: usageSort.usageStatus }
    : usageSort.parkingAddressSort
    ? { sortColumn: UsageSortColumn.PARKING_ADDRESS, sortDirection: usageSort.parkingAddressSort }
    : usageSort.usagePeriodSort
    ? { sortColumn: UsageSortColumn.USAGE_PERIOD, sortDirection: usageSort.usagePeriodSort }
    : usageSort.accountNameSort
    ? { sortColumn: UsageSortColumn.ACCOUNT_NAME, sortDirection: usageSort.accountNameSort }
    : { sortColumn: UsageSortColumn.NONE, sortDirection: sortDirection.desc };
};
