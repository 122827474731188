import React, { useState, useCallback } from "react";

import ErrorModalStatusContext from "contexts/ErrorModalStatusContext";

// 本体
const ErrorModalStatusProvider = ({ children }: { children: React.ReactNode }) => {
  const [httpMethod, setHttpMethod] = useState<HttpMethod>("get");
  const [isVisible, setIsVisible] = useState(false);
  const [onPostClose, setOnPostClose] = useState<() => void>(() => undefined);

  // モーダルを閉じた後に実行する関数
  const onClose = (): void => {
    setIsVisible(false);
    onPostClose();
  };

  // 各ページで使う関数
  // モーダルのエラーメッセージと閉じる時に実行する関数をセットして、エラーモーダルを表示する
  const showErrorModal = useCallback(
    ({ httpMethod, onPostClose = () => undefined }: { httpMethod: HttpMethod; onPostClose?: () => void }) => {
      setHttpMethod(httpMethod);
      setOnPostClose(() => onPostClose);
      setIsVisible(true);
    },
    []
  );

  const value = {
    // App.tsxから使う
    httpMethod,
    isVisible,
    onClose,
    // 各ページから使う
    showErrorModal,
  };

  return <ErrorModalStatusContext.Provider value={value}>{children}</ErrorModalStatusContext.Provider>;
};

export default ErrorModalStatusProvider;
