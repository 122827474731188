import { PhoneAuthProvider, PhoneInfoOptions, PhoneMultiFactorGenerator, multiFactor, signOut } from "firebase/auth";
import { Suspense, lazy, useCallback, useEffect, useState } from "react";
import { isBrowser, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";
import useGetUserMfa from "hooks/api/useGetUserMfa";
import useFirebaseContext from "hooks/useFirebaseContext";
import { useInfoModal, useNoticeModal } from "hooks/useModal";
import { useRecaptcha } from "hooks/useRecaptcha";
import useUserContext from "hooks/useUserContext";

const PhoneRegistrationPresenter = lazy(() =>
  isBrowser
    ? import("pages/MfaSetting/pc/PhoneRegistrationPresenter")
    : isTablet
    ? import("pages/MfaSetting/pc/PhoneRegistrationPresenter")
    : import("pages/MfaSetting/sp/PhoneRegistrationPresenter")
);

const CodeSignupPresenter = lazy(() =>
  isBrowser
    ? import("pages/MfaSetting/pc/CodeSignupPresenter")
    : isTablet
    ? import("pages/MfaSetting/tb/CodeSignupPresenter")
    : import("pages/MfaSetting/sp/CodeSignupPresenter")
);

const MfaSettingFinishPresenter = lazy(() =>
  isBrowser
    ? import("pages/MfaSetting/pc/MfaSettingFinishPresenter")
    : isTablet
    ? import("pages/MfaSetting/tb/MfaSettingFinishPresenter")
    : import("pages/MfaSetting/sp/MfaSettingFinishPresenter")
);

// 本体
const MfaSettingContainer = () => {
  const navigate = useNavigate();
  const { auth } = useFirebaseContext();
  const { recaptcha, refreshRecaptcha } = useRecaptcha(auth, "recaptcha-container");
  const { InfoModal, showInfoModal, hideInfoModal } = useInfoModal();
  const { NoticeModal: ReLoginModal, showNoticeModal: showReLoginModal } = useNoticeModal();
  const [verificationId, setVerificationId] = useState<string | null>(null);
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [step, setStep] = useState(1);
  const [canReSendCode, setCanReSendCode] = useState<boolean>(true);
  const { user } = useUserContext();
  const { userMfaInfo, mutate: mutateUserMfa } = useGetUserMfa();
  const phoneNumberWithCountryCode = `+81${phoneNumber}`;
  const code = new Array<string>(6).fill("");
  const canSubmit = phoneNumber.length > 9;

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value);

  const verifyPhone = async () => {
    if (!auth || !recaptcha || !auth.currentUser) {
      return null;
    }

    const multiFactorSession = await multiFactor(auth.currentUser)
      .getSession()
      .catch((e) => {
        throw e;
      });

    const phoneInfoOptions: PhoneInfoOptions = {
      phoneNumber: phoneNumberWithCountryCode,
      session: multiFactorSession,
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const verificationCodeId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha).catch((e) => {
      throw e;
    });
    if (verificationCodeId) {
      setVerificationId(verificationCodeId);
      setPhoneNumber(phoneNumber);
    }
  };

  const navMfa = useCallback(() => {
    mutateUserMfa();
    setIsFinished(true);
  }, [setIsFinished, mutateUserMfa]);

  useEffect(() => {
    // userMfaの反映にラグがあるのでuseEffectで処理している
    if (isFinished && userMfaInfo != null && userMfaInfo.isMfaEnabled) {
      if (user?.isRequiredMfa) {
        navigate("/");
      } else {
        navigate("/mfa");
      }
    }
  }, [navigate, user?.isRequiredMfa, userMfaInfo, isFinished]);

  // email認証が完了していないとMFA設定に失敗するので、強制的にemail認証画面に遷移させる
  useEffect(() => {
    if (auth.currentUser && auth.currentUser.emailVerified == false) navigate("/mfa/email-verify");
  }, [auth.currentUser, navigate]);

  const toSupportPage = useCallback(() => {
    window.open("https://landit.co.jp/contact/", "_blank");
  }, []);

  const onClickNextFirst = async () => {
    let canNextStep = true;
    await verifyPhone().catch((e) => {
      canNextStep = false;
      console.error(e);
      if (e.code === "auth/requires-recent-login") {
        showReLoginModal();
      } else {
        setErrorMessage("エラーが発生しました.");
      }
    });
    if (canNextStep) setStep(2);
  };

  const reSendCode = async () => {
    if (!canReSendCode || !auth || !auth.currentUser) {
      return;
    }

    const phoneNumberWithCountryCode = "+81" + phoneNumber;
    const session = await multiFactor(auth.currentUser).getSession();

    const phoneInfoOptions = {
      phoneNumber: phoneNumberWithCountryCode,
      session: session,
    };

    const recaptureReSend = refreshRecaptcha();
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const verificationCodeId = await phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, recaptureReSend)
      .catch((e) => {
        throw e;
      });

    setVerificationId(verificationCodeId);
    setPhoneNumber(phoneNumber);
    setCanReSendCode(false);
    showInfoModal();
  };

  const getCode = async (code: string) => {
    let canNextStep = true;
    if (!auth.currentUser || !verificationId) {
      return false;
    }

    const phoneAuthCredential = PhoneAuthProvider.credential(verificationId, code);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
    await multiFactor(auth.currentUser)
      .enroll(multiFactorAssertion, "MFA Phone Number")
      .catch((e) => {
        if (e.code === "auth/requires-recent-login") {
          showReLoginModal();
          canNextStep = false;
        } else {
          throw e;
        }
      });

    // Unenroll old MFA entries in the current user by uid.
    const checkMfaEnrollment = multiFactor(auth.currentUser).enrolledFactors;
    if (checkMfaEnrollment.length > 1) {
      await multiFactor(auth.currentUser).unenroll(checkMfaEnrollment[0].uid);
    }
    if (canNextStep) setStep(3);
  };

  const onClickNextSecond = async () => {
    const finalCode = code.reduce((previousValue, currentValue) => {
      return previousValue.concat(currentValue);
    });

    await getCode(finalCode);
  };

  const onCloseModal = () => {
    hideInfoModal();
  };

  const logout = async () => {
    await signOut(auth);
    navigate("/login", { replace: true, state: { from: "/mfa" } });
  };

  useEffect(() => {
    setErrorMessage("");
  }, [step]);

  return (
    <Suspense fallback={<Loading />}>
      {step === 1 && (
        <PhoneRegistrationPresenter
          step={step}
          errorMessage={errorMessage}
          phoneNumber={phoneNumber}
          canSubmit={canSubmit}
          isRequiredMfa={user.isRequiredMfa}
          onChangePhoneNumber={onChangePhoneNumber}
          navMfa={navMfa}
          onClickNextFirst={onClickNextFirst}
        />
      )}
      {step === 2 && (
        <CodeSignupPresenter
          step={step}
          setStep={setStep}
          errorMessage={errorMessage}
          code={code}
          canReSendCode={canReSendCode}
          reSendCode={reSendCode}
          toSupportPage={toSupportPage}
          onClickNextSecond={onClickNextSecond}
          onCloseModal={onCloseModal}
          InfoModal={InfoModal}
        />
      )}
      {step === 3 && (
        <MfaSettingFinishPresenter step={step} errorMessage={""} navMfa={navMfa} isRequiredMfa={user.isRequiredMfa} />
      )}

      <div id="recaptcha-container"></div>
      <ReLoginModal
        title="再ログインが必要です"
        message={
          <div style={{ padding: "0 16px 0 16px", textAlign: "center" }}>
            <p>2段階認証のため</p>
            <p>ログインし直す必要があります</p>
          </div>
        }
        buttonText="再ログインする"
        onClick={logout}
      />
    </Suspense>
  );
};

export default MfaSettingContainer;
