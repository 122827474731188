import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";

const useFindOption = (
  optionId?: string
): {
  option: Option;
  error: Error;
  mutate: KeyedMutator<Option>;
} => {
  const { data, error, mutate } = useSwr<Option>(optionId ? `${API_URL}/option/${optionId}` : null);

  return { option: data, error, mutate };
};

export default useFindOption;
