import { faWrench } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding-top: 180px;
`;
const Title = styled.span`
  font-size: ${font.size24};
`;
const BoldMessage = styled.span`
  font-weight: 700;
`;
const Message = styled.span`
  font-weight: 400;
  color: ${color.text.gray};
`;
const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MaintenancePresenter = ({ maintenanceDateString }: { maintenanceDateString?: string }) => (
  <Body>
    <Wrap>
      <FontAwesomeIcon icon={faWrench} style={{ color: color.text.gray }} />
      <Title>ただいまメンテナンス中です</Title>
    </Wrap>
    {maintenanceDateString && (
      <Wrap>
        <BoldMessage>メンテナンス日時</BoldMessage>
        <BoldMessage>{maintenanceDateString}</BoldMessage>
      </Wrap>
    )}
    <Wrap>
      <Message>ご迷惑をおかけしますが、</Message>
      <Message>メンテナンス終了までしばらくお待ちください</Message>
    </Wrap>
  </Body>
);

export default MaintenancePresenter;
