import queryString from "query-string";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";

export type FindProgressSummaryParameter = {
  contact?: string;
  department?: string;
};

export type ProgressSummary = {
  inProgressCount: number;
  unansweredQuoteCount: number;
  waitingQuoteCount: number;
  uncontractedCount: number;
  notDownloadedBillingCount: number;
  inUsingCount: number;
  beforeUsingCount: number;
};

const initialProgressSummary: ProgressSummary = {
  inProgressCount: 0,
  unansweredQuoteCount: 0,
  waitingQuoteCount: 0,
  uncontractedCount: 0,
  notDownloadedBillingCount: 0,
  inUsingCount: 0,
  beforeUsingCount: 0,
};

const useFindProgressSummary = (
  parameter: FindProgressSummaryParameter
): {
  progressSummary: ProgressSummary;
  error: Error;
} => {
  const { data, error } = useSwr<ProgressSummary>(`${API_URL}/order/progress?${queryString.stringify(parameter)}`, {
    revalidateIfStale: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return {
    progressSummary: data ?? initialProgressSummary,
    error,
  };
};

export default useFindProgressSummary;
