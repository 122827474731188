import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";

// Firebase Authenticationのメールのリンクの処理を行うためのコンテナ
// パスワードリセット、メールアドレスの確認で異なるURLを指定できない
// そのため、一旦このコンテナで受けて、URLパラメータのmodeを見てリダイレクトさせる
const AuthActionContainer = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const mode = new URLSearchParams(window.location.search).get("mode");

    if (mode == "resetPassword") {
      navigate(`/password-reset/change${window.location.search}`);
    } else if (mode == "verifyEmail") {
      navigate(`/mfa/email-verify-complete${window.location.search}`);
    } else if (mode == "revertSecondFactorAddition") {
      navigate(`/mfa/revert${window.location.search}`);
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};

export default AuthActionContainer;
