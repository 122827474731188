import { NavigateFunction, NavigateOptions, To, useNavigate } from "react-router-dom";

const usePageTransition = (): NavigateFunction => {
  const navigate = useNavigate();

  const pageTransition = (to: To | number, options?: NavigateOptions): void => {
    typeof to !== "number" ? navigate(to, options) : navigate(to);
    window.scrollTo(0, 0);
  };

  return pageTransition;
};

export default usePageTransition;
