import { useCallback } from "react";

import useClient from "hooks/useClient";

const useUpdateOption = () => {
  const { client } = useClient();

  const updateOption = useCallback(
    async ({ name, requestService, orderId, otherRequestContent, province, district, street, remarks, id }) => {
      const { status } = await client({
        method: "put",
        url: `/option/${id}`,
        data: {
          name,
          requestService,
          orderId,
          otherRequestContent,
          province,
          district,
          street,
          // key変わってるので注意
          others: remarks,
        },
      });
      return status;
    },
    [client]
  );

  return { updateOption };
};

export default useUpdateOption;
