import queryString from "query-string";
import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { SortDirection } from "constants/sortDirection";
import { useSwr } from "hooks/useSwr";
import { UsageSortColumn } from "pages/Usage/usageSortColumn";
import { UsageStatusFilter } from "pages/Usage/usageStatus";

export type FindContractsParams = {
  pageSize: number;
  pageNumber: number;
  usageStatusFilter: UsageStatusFilter;
  sortDirection: SortDirection;
  sortColumn: UsageSortColumn;
  contactId?: string;
  department?: string;
  searchKeyword?: string;
};

const useFindContracts = (
  params: FindContractsParams
): {
  contractsResponse: ContractsResponse;
  error: Error;
  mutate: KeyedMutator<ContractsResponse>;
} => {
  const { data, error, mutate } = useSwr<ContractsResponse>(`${API_URL}/contract?${queryString.stringify(params)}`);

  return { contractsResponse: data, error, mutate };
};

export default useFindContracts;
