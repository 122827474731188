import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";

const useFindMonthlyBillingSummary = ({
  billingYear,
  billingMonth,
}: {
  billingYear: string;
  billingMonth: string;
}): {
  monthlyBillingSummary: MonthlyBillingSummary | undefined;
  error: Error & { response?: Response };
  mutate: KeyedMutator<MonthlyBillingSummary>;
} => {
  const {
    data: monthlyBillingSummaryResponseBody,
    error,
    mutate,
  } = useSwr<MonthlyBillingSummary>(`${API_URL}/monthly-billing/${billingYear}/${billingMonth}`, {
    onErrorRetry: (error) => {
      if (error.response?.data?.statusCode == 404) return;
    },
  });

  if (!monthlyBillingSummaryResponseBody)
    return {
      monthlyBillingSummary: undefined,
      error,
      mutate,
    };

  return {
    monthlyBillingSummary: monthlyBillingSummaryResponseBody,
    error,
    mutate,
  };
};

export default useFindMonthlyBillingSummary;
