import { useCallback } from "react";

import useUnauthorizedClient from "hooks/useUnauthorizedClient";

const usePostEntryParams = () => {
  const { unauthorizedClient } = useUnauthorizedClient();

  const postEntryParams = useCallback(
    async (companyName: string, name: string, email: string, phoneNumber: string) => {
      await unauthorizedClient({
        method: "post",
        url: `/entry`,
        data: {
          companyName,
          name,
          email,
          phoneNumber,
        },
      });
    },
    [unauthorizedClient]
  );

  return { postEntryParams };
};

export default usePostEntryParams;
