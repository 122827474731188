import dayjs from "dayjs";
import { useCallback } from "react";

import useClient from "hooks/useClient";
import { convertFileToBase64String } from "utils/fileUtils";

const useUpdateOrder = (): {
  updateOrder: (order: Order) => Promise<number>;
} => {
  const { client } = useClient();

  const updateOrder = useCallback(
    async (order: Order) => {
      const { status } = await client({
        method: "put",
        url: `/order/${order.id}`,
        data: {
          id: order.id,
          name: order.constructionName,
          address: order.constructionAddress,
          numberOfCars: order.numberOfCars,
          automobileType: order.automobileType,
          startDate: dayjs(order.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(order.endDate).format("YYYY-MM-DD"),
          remarks: order.remarks,
          attachments: [
            ...(await Promise.all(
              order.attachments.filter((x) => x instanceof File).map((x) => convertFileToBase64String(x as File))
            )),
            ...order.attachments.filter((x) => !(x instanceof File) && x.deleted),
          ],
        },
      });
      return status;
    },
    [client]
  );

  return { updateOrder };
};

export default useUpdateOrder;
