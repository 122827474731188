import { useCallback } from "react";

import useClient from "hooks/useClient";

const useShareEstimates = () => {
  const { client } = useClient();

  const shareEstimates = useCallback(
    async (shareEstimateParams: ShareEstimates) => {
      const { status } = await client({
        method: "post",
        url: `/order/:orderId/estimate/send-estimate-pdfs-on-customers`,
        data: {
          estimateIds: shareEstimateParams.estimateIds,
          emails: shareEstimateParams.transformedEmails,
          remarks: shareEstimateParams.remarks ?? undefined,
        },
      });
      return status;
    },
    [client]
  );
  return { shareEstimates };
};

export default useShareEstimates;
