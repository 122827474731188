import React from "react";

type State = {
  modalType: string | null;
  onClose: () => void;
  showModal: ({ modalType }: { modalType: string }) => void;
  setAfterClose: (afterCloseEvent: () => void) => void;
};

const SharedModalContext = React.createContext({} as State);

export default SharedModalContext;
