import { Suspense, lazy } from "react";
import { isBrowser, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";

const MfaEmailVerifySentPresenter = lazy(() =>
  isBrowser
    ? import("pages/MfaEmailVerify/Sent/pc/MfaEmailVerifySentPresenter")
    : isTablet
    ? import("pages/MfaEmailVerify/Sent/tb/MfaEmailVerifySentPresenter")
    : import("pages/MfaEmailVerify/Sent/sp/MfaEmailVerifySentPresenter")
);

const MfaEmailVerifySentContainer = () => {
  const navigate = useNavigate();
  const back = () => {
    navigate("/mfa");
  };

  return (
    <Suspense fallback={<Loading />}>
      <MfaEmailVerifySentPresenter onClose={back} />
    </Suspense>
  );
};

export default MfaEmailVerifySentContainer;
