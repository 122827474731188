import dayjs from "dayjs";
import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";

const useCountMonthlyBillings = (
  year: string
): {
  monthly: MonthWithCount[];
  error: Error;
  mutate: KeyedMutator<MonthWithCount[]>;
} => {
  const { data, error, mutate } = useSwr<MonthWithCount[]>(`${API_URL}/billing/monthly/${year}`);

  // dayjsの仕様で、月の値は0から11までなので、+1して返す
  if (!data) return { monthly: [{ month: `${dayjs().month() + 1}`, countResult: 0 }], error, mutate };

  return {
    monthly: data.sort((a, b) => Number(b.month) - Number(a.month)),
    error,
    mutate,
  };
};

export default useCountMonthlyBillings;
