import { useCallback } from "react";

import useClient from "hooks/useClient";
import { convertFileToBase64String } from "utils/fileUtils";

const useUploadContract = (): { uploadContract: (data: UploadContract, contractId: string) => Promise<number> } => {
  const { client } = useClient();

  const uploadContract = useCallback(
    async (data: UploadContract, contractId: string) => {
      const { status } = await client({
        method: "post",
        url: `/contract/pdf/${contractId}`,
        data: {
          attachment: await convertFileToBase64String(data.attachment as File),
        },
      });
      return status;
    },
    [client]
  );
  return { uploadContract };
};

export default useUploadContract;
