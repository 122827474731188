import {
  AuthErrorCodes,
  browserLocalPersistence,
  browserSessionPersistence,
  signInWithEmailAndPassword,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  getMultiFactorResolver,
  MultiFactorResolver,
  RecaptchaVerifier,
  MultiFactorError,
} from "firebase/auth";
import { lazy, Suspense, useCallback, useState } from "react";
import { isBrowser, isTablet } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";

import { FullPageLoading } from "components/FullPageLoading";
import useFirebaseContext from "hooks/useFirebaseContext";
import { useInfoModal } from "hooks/useModal";
import { useRecaptcha } from "hooks/useRecaptcha";
const IS_KEEP_LOGIN_KEY = "keep-login";

const findKeepLoginIsChecked = (): boolean => {
  const maybeItem = localStorage.getItem(IS_KEEP_LOGIN_KEY);
  return maybeItem ? JSON.parse(maybeItem) : false;
};

const setKeepLoginIsChecked = (isCheckedKeepLogin: boolean): void => {
  isCheckedKeepLogin
    ? localStorage.setItem(IS_KEEP_LOGIN_KEY, JSON.stringify(isCheckedKeepLogin))
    : localStorage.removeItem(IS_KEEP_LOGIN_KEY);
};

const createErrorMessage = (code: string): string => {
  switch (code) {
    case AuthErrorCodes.INVALID_PASSWORD:
    case AuthErrorCodes.USER_DELETED:
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
    case "auth/invalid-login-credentials":
      return "メールアドレスまたはパスワードが正しくありません";
    default:
      return "予期せぬエラーが発生しました。しばらく時間をおいてお試しください";
  }
};

const LoginPresenter = lazy(() =>
  isBrowser
    ? import("pages/Login/pc/LoginPresenter")
    : isTablet
    ? import("pages/Login/tb/LoginPresenter")
    : import("pages/Login/sp/LoginPresenter")
);

const CodeSignInPresenter = lazy(() =>
  isBrowser
    ? import("pages/Login/pc/CodeSignInPresenter")
    : isTablet
    ? import("pages/Login/tb/CodeSignInPresenter")
    : import("pages/Login/sp/CodeSignInPresenter")
);

const LoginContainer = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isCheckedKeepLogin, setIsCheckedKeepLogin] = useState(findKeepLoginIsChecked());
  const [errorMessage, setErrorMessage] = useState("");
  const { auth } = useFirebaseContext();

  const { recaptcha } = useRecaptcha(auth, "recaptcha-container");

  const navigate = useNavigate();
  const location = useLocation();
  const { from } = (location.state as { from: string }) || { from: "/" };
  const { InfoModal, showInfoModal, hideInfoModal } = useInfoModal();
  const [canReSendCode, setCanReSendCode] = useState<boolean>(true);
  const [verificationId, setVerificationId] = useState<string>();
  const [resolver, setResolver] = useState<MultiFactorResolver>();

  const canSubmit = !email || !password;
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  const handleMFA = async (response: MultiFactorError) => {
    if (response.code === AuthErrorCodes.MFA_REQUIRED) {
      setErrorMessage("");

      const resolver = getMultiFactorResolver(auth, response);
      const selectedIndex = 0;
      if (resolver.hints[selectedIndex].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        if (!recaptcha) {
          return false;
        }
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[selectedIndex],
          session: resolver.session,
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const verificationCodeId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptcha).catch((e) => {
          throw e;
        });

        if (verificationCodeId) {
          setVerificationId(verificationCodeId);
          setResolver(resolver);
        }
      }
    } else {
      setErrorMessage(createErrorMessage(response.code));
    }
  };

  const login = async () => {
    setErrorMessage("");
    setKeepLoginIsChecked(isCheckedKeepLogin);
    // https://firebase.google.com/docs/auth/web/auth-state-persistence
    await auth.setPersistence(isCheckedKeepLogin ? browserLocalPersistence : browserSessionPersistence);

    const result = await signInWithEmailAndPassword(auth, email, password).catch(async (e) => {
      await handleMFA(e);
    });

    if (result) {
      navigate(from || "/");
    }
  };

  const toEntryPage = useCallback(() => {
    navigate("/entry");
  }, [navigate]);

  // code sign in
  const code = new Array<string>(6).fill("");
  const canSubmitCode = !code;

  const reSendCode = async () => {
    if (!canReSendCode || !resolver) {
      return;
    }

    // reSend code
    const recaptchaReSend = new RecaptchaVerifier(auth, "recaptcha-container-resend", {
      size: "invisible",
    });

    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session,
    };

    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const verificationCodeId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaReSend);

    setVerificationId(verificationCodeId);
    setCanReSendCode(false); //disable resend link
    showInfoModal();
  };

  const getCode = async (verificationId: string, code: string) => {
    const cred = PhoneAuthProvider.credential(verificationId, code);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    // Complete sign-in.
    if (!resolver) {
      return;
    }
    const response = await resolver.resolveSignIn(multiFactorAssertion);
    if (response) {
      navigate(from || "/");
    } else {
      setErrorMessage(createErrorMessage(response));
    }
  };

  const handleClick = async () => {
    const finalCode = code.reduce((previousValue, currentValue) => {
      return previousValue.concat(currentValue);
    });

    if (verificationId) {
      await getCode(verificationId, finalCode);
    }
  };

  const toRootPage = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const toSupportPage = useCallback(() => {
    window.open("https://landit.co.jp/contact/", "_blank");
  }, []);

  const onCloseModal = () => {
    hideInfoModal();
  };

  return (
    <Suspense fallback={<FullPageLoading />}>
      {!verificationId && (
        <LoginPresenter
          email={email}
          password={password}
          errorMessage={errorMessage}
          isCheckedKeepLogin={isCheckedKeepLogin}
          canSubmit={canSubmit}
          login={login}
          onChangeEmail={onChangeEmail}
          onChangePassword={onChangePassword}
          setIsCheckedKeepLogin={setIsCheckedKeepLogin}
          toEntryPage={toEntryPage}
        />
      )}
      {verificationId && (
        <CodeSignInPresenter
          code={code}
          canSubmitCode={canSubmitCode}
          canReSendCode={canReSendCode}
          reSendCode={reSendCode}
          handleClick={handleClick}
          toSupportPage={toSupportPage}
          toRootPage={toRootPage}
          errorMessage={errorMessage}
          onCloseModal={onCloseModal}
          InfoModal={InfoModal}
        />
      )}
      <div id="recaptcha-container"></div>
    </Suspense>
  );
};

export default LoginContainer;
