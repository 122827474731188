/**
 *
 * @param estimates Estimate[]
 * @param status EstimateStatus
 * @returns
 */
export const collect = (estimates: Estimate[], status: EstimateStatus): Estimate[] => {
  return estimates && estimates.length > 0 ? estimates.filter((estimate) => estimate.status === status) : [];
};

/**
 * minutesOnFoot で sort する
 * @param estimates Estimate[]
 * @param status EstimateStatus
 * @returns
 */
export const sortCollect = (estimates: Estimate[], status: EstimateStatus): Estimate[] => {
  const results = collect(estimates, status);

  return results.sort((a, b) => a.minutesOnFoot - b.minutesOnFoot);
};

/**
 *
 * @param estimates 母集団
 * @param estimateId 更新する対象の見積提案のid
 * @param destinationStatus 更新後の見積提案のステータス
 */
export const updateEstimateStatus = (
  estimates: Estimate[],
  estimateId: string,
  destinationStatus: EstimateStatus
): Estimate[] => {
  return estimates.map((estimate) => {
    if (estimate.id === estimateId) return { ...estimate, status: destinationStatus };
    return estimate;
  });
};

export const displayEstimateStatus = (status: EstimateStatus) => {
  switch (status) {
    case "unanswered":
      return "未回答";
    case "accepted":
      return "依頼済";
    case "rejected":
      return "見送り";
    case "contracted":
      return "契約済";
    case "aborted":
      return "依頼不可";
    default:
      return "";
  }
};
