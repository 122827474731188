import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";

const useFindContractById = (
  contractId: string
): {
  contract: ContractDetail;
  error: Error;
  mutate: KeyedMutator<ContractDetail>;
} => {
  const { data: contract, error, mutate } = useSwr<ContractDetail>(`${API_URL}/contract/${contractId}`);

  return { contract: contract, error, mutate };
};

export default useFindContractById;
