import { useCallback } from "react";

import useClient from "hooks/useClient";

const useStopSearchProperty = () => {
  const { client } = useClient();

  const stopSearchProperty = useCallback(
    async (orderId: string | null) => {
      if (orderId == null) throw new Error("orderId is null");

      const { status } = await client({
        method: "put",
        url: `/order/${orderId}/search-stop/toggle`,
      });
      return status;
    },
    [client]
  );

  return { stopSearchProperty };
};

export default useStopSearchProperty;
