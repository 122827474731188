import { useCallback } from "react";

import useClient from "hooks/useClient";

const useFindBillingAttachment = (): {
  findBillingAttachment: (billingId: string) => Promise<Attachment>;
} => {
  const { client } = useClient();

  const findBillingAttachment = useCallback(
    async (billingId: string) => {
      const { data } = await client({
        method: "get",
        url: `/billing/downloaded-pdf/${billingId}`,
      });
      return data as Attachment;
    },
    [client]
  );

  return { findBillingAttachment };
};

export default useFindBillingAttachment;
