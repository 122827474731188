import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";

const useFindEstimates = (
  orderId: string
): {
  estimates: Estimate[];
  error: Error;
  mutate: KeyedMutator<Estimate[]>;
  isValidating?: boolean;
} => {
  const { data, error, mutate, isValidating } = useSwr<Estimate[]>(`${API_URL}/order/${orderId}/estimate`);

  return { estimates: data, error, mutate, isValidating };
};

export default useFindEstimates;
