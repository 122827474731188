import type { Analytics } from "firebase/analytics";
import type { Auth } from "firebase/auth";
import { FirebaseStorage } from "firebase/storage";
import React from "react";

type FirebaseContextState = {
  analytics: Analytics;
  auth: Auth;
  storage: FirebaseStorage;
  config: {
    isMaintenanceMode: boolean;
    maintenanceDateString: string;
  };
};

const FirebaseContext = React.createContext({} as FirebaseContextState);

export default FirebaseContext;
