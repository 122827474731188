export const sortDirection = {
  asc: "asc",
  desc: "desc",
} as const;

export const reverse = (direction?: SortDirection) => {
  switch (direction) {
    case sortDirection.asc:
      return sortDirection.desc;
    case sortDirection.desc:
      return sortDirection.asc;
    default:
      return sortDirection.desc;
  }
};

export type SortDirection = typeof sortDirection[keyof typeof sortDirection];
