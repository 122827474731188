import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { useSwr } from "hooks/useSwr";

const useGetOrder = (
  orderId: string
): {
  order: Order;
  error: Error;
  mutate: KeyedMutator<Order>;
} => {
  const { data, error, mutate } = useSwr<Order>(orderId ? `${API_URL}/order/${orderId}` : null);

  return { order: data, error, mutate };
};

export default useGetOrder;
