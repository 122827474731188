import { Auth, RecaptchaVerifier } from "firebase/auth";
import { useEffect, useState } from "react";

export function useRecaptcha(auth: Auth, componentId: string) {
  const [recaptcha, setRecaptcha] = useState<RecaptchaVerifier>();

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(auth, componentId, {
      size: "invisible",
    });

    setRecaptcha(recaptchaVerifier);

    return () => {
      recaptchaVerifier.clear();
    };
  }, [auth, componentId]);

  const refreshRecaptcha = () => {
    const recaptchaVerifier = new RecaptchaVerifier(auth, componentId, {
      size: "invisible",
    });

    setRecaptcha(recaptchaVerifier);
    return recaptchaVerifier;
  };

  return { recaptcha, refreshRecaptcha };
}
