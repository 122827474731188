import { Suspense, lazy, useState } from "react";
import { isBrowser, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";
import useFirebaseAuth from "hooks/useFirebaseAuth";

const MfaEmailVerifyPresenter = lazy(() =>
  isBrowser
    ? import("pages/MfaEmailVerify/pc/MfaEmailVerifyPresenter")
    : isTablet
    ? import("pages/MfaEmailVerify/tb/MfaEmailVerifyPresenter")
    : import("pages/MfaEmailVerify/sp/MfaEmailVerifyPresenter")
);

const MfaStatusContainer = () => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { currentUser, sendEmailVerification } = useFirebaseAuth();
  const navigate = useNavigate();

  const sendEmail = async () => {
    if (currentUser?.email == null) return;

    let failed = false; // Flag to check if the email send failed

    await sendEmailVerification().catch((e) => {
      setErrorMessage(e.message);
      failed = true;
    });

    if (!failed) {
      navigate("/mfa/email-verify-sent");
    }
  };

  const back = () => {
    navigate(-1);
  };

  return (
    <Suspense fallback={<Loading />}>
      {currentUser?.email && (
        <MfaEmailVerifyPresenter
          email={currentUser.email}
          errorMessage={errorMessage}
          onSend={sendEmail}
          onClose={back}
        />
      )}
    </Suspense>
  );
};

export default MfaStatusContainer;
