import { useCallback } from "react";

import useClient from "hooks/useClient";

const useAcceptEstimate = (): {
  acceptEstimate: (orderId: string, estimateId: string, userRequest: string) => Promise<number>;
} => {
  const { client } = useClient();

  const acceptEstimate = useCallback(
    async (orderId: string, estimateId: string, userRequest: string) => {
      const { status } = await client({
        method: "patch",
        url: `/order/${orderId}/estimate/${estimateId}/accept`,
        data: { userRequest },
      });
      return status;
    },
    [client]
  );

  return { acceptEstimate };
};

export default useAcceptEstimate;
