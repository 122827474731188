import React, { useState, useCallback } from "react";

import SharedModalContext from "contexts/SharedModalContext";

const SharedModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [modalType, setModalType] = useState<string | null>(null);
  const [afterCloseEvent, setAfterCloseEvent] = useState<() => void>();

  const onClose = (): void => {
    setModalType(null);
    if (afterCloseEvent) afterCloseEvent();
  };

  const showModal = useCallback(({ modalType }: { modalType: string }) => {
    setModalType(modalType);
  }, []);

  const setAfterClose = useCallback((afterCloseEvent: () => void) => {
    setAfterCloseEvent(afterCloseEvent);
  }, []);

  const value = {
    modalType,
    onClose,
    showModal,
    setAfterClose,
  };

  return <SharedModalContext.Provider value={value}>{children}</SharedModalContext.Provider>;
};

export default SharedModalProvider;
