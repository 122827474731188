/**
 * LocalStorageからKeyに紐づく値を取得する。
 * @param key key
 * @param defaultValue デフォルト値
 */
export const findFromLocalStorage = <T>(key: string, defaultValue: T): T => {
  const storageValue = localStorage.getItem(key);
  return storageValue ? JSON.parse(storageValue) : defaultValue;
};

export const saveToLocalStorage = <T>(key: string, value: T): void => {
  return localStorage.setItem(key, JSON.stringify(value));
};
