import { useEffect, useState } from "react";
/**
 * https://zenn.dev/luvmini511/articles/4924cc4cf19bc9
 */
export const useDebounce = <T>(value: T, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return { debouncedValue };
};
