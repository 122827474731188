import { Suspense } from "react";

import Loading from "components/Loading";
import MaintenancePresenter from "pages/Maintenance/MaintenancePresenter";

const MaintenanceContainer = ({ maintenanceDateString }: { maintenanceDateString?: string }) => (
  <Suspense fallback={<Loading />}>
    <MaintenancePresenter maintenanceDateString={maintenanceDateString} />
  </Suspense>
);

export default MaintenanceContainer;
