import { useCallback } from "react";

import useClient from "hooks/useClient";

const useCreateOption = () => {
  const { client } = useClient();

  const createOption = useCallback(
    async ({ name, requestService, orderId, otherRequestContent, province, district, street, remarks }) => {
      const { status } = await client({
        method: "post",
        url: `/option`,
        data: {
          name,
          requestService,
          orderId,
          otherRequestContent,
          province,
          district,
          street,
          // key変わってるので注意
          others: remarks,
        },
      });
      return status;
    },
    [client]
  );

  return { createOption };
};

export default useCreateOption;
