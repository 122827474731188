import { API_URL } from "constants/api";
import { useSwrMutation } from "hooks/useSwrMutation";

const useFindMonthlyBillingAttachment = (monthlyBillingId: string | null) => {
  const { data, error, isMutating, trigger } = useSwrMutation<Attachment>(
    monthlyBillingId ? API_URL + `/monthly-billing/downloaded-pdf/${monthlyBillingId}` : null
  );

  return {
    attachment: data,
    error,
    isMutating,
    trigger,
  };
};

export default useFindMonthlyBillingAttachment;
