import { isPhoneNumber, isEmail, maxLength } from "class-validator";
import { ChangeEvent, Suspense, lazy, useState } from "react";
import { isBrowser, isTablet } from "react-device-detect";
import styled from "styled-components";

import ErrorModal from "components/ErrorModal";
import { FullPageLoading } from "components/FullPageLoading";
import usePostEntryParams from "hooks/api/usePostEntryParams";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";

const EntryPresenter = lazy(() =>
  isBrowser
    ? import("pages/Entry/pc/EntryPresenter")
    : isTablet
    ? import("pages/Entry/tb/EntryPresenter")
    : import("pages/Entry/sp/EntryPresenter")
);

const ModalTitle = styled.div`
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 16px;
`;

const Message = styled.p`
  font-size: 16px;
`;

export type KeyOfEntryParam = {
  name: string;
  value: string;
  type: "number" | "text" | "email" | "tel" | "url" | undefined;
  placeHolder: string;
  inputMode: "text" | "search" | "email" | "tel" | "url" | "numeric" | "decimal";
  error?: boolean;
  errorMessage?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  testid?: string;
};

export const CompleteModalMessage = () => {
  return (
    <>
      <ModalTitle>ご利用申請いただきありがとうございます</ModalTitle>
      <Message>
        登録情報の確認が完了し、サービスのご利用が可能となり次第、登録メールアドレス宛てにご案内をお送りします。
      </Message>
    </>
  );
};

export const FormMessage = () => {
  return (
    <Message>
      サービス利用開始のため、
      <br />
      以下の必要情報をご入力ください
    </Message>
  );
};

const EntryContainer = () => {
  const [userName, setUserName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [submitted, setSubmitted] = useState(false);

  const isErrorUserName = !maxLength(userName, 20) || !userName;
  const isErrorCompanyName = !maxLength(companyName, 256) || !companyName;
  const isErrorPhoneNumber = !isPhoneNumber(phoneNumber, "JP");
  const isErrorEmail = !isEmail(email);

  const disableSubmit = isErrorUserName || isErrorCompanyName || isErrorEmail || isErrorPhoneNumber;

  const params: KeyOfEntryParam[] = [
    {
      name: "会社名",
      value: companyName,
      placeHolder: "例) landit株式会社",
      type: "text",
      error: isErrorCompanyName,
      inputMode: "text",
      errorMessage: "会社名を入力してください",
      onChange: (e: ChangeEvent<HTMLInputElement>) => setCompanyName(e.target.value),
      testid: "companyNameInput",
    },
    {
      name: "担当者名",
      value: userName,
      type: "text",
      placeHolder: "例) 田中太郎",
      inputMode: "text",
      error: isErrorUserName,
      errorMessage: "担当者を20文字以内で入力してください",
      onChange: (e: ChangeEvent<HTMLInputElement>) => setUserName(e.target.value),
      testid: "userNameInput",
    },
    {
      name: "メールアドレス",
      value: email,
      type: "email",
      placeHolder: "半角英数字",
      inputMode: "email",
      error: isErrorEmail,
      errorMessage: "メールアドレスを正しい形式で入力してください",
      onChange: (e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value),
      testid: "emailInput",
    },
    {
      name: "電話番号",
      value: phoneNumber,
      type: "text",
      placeHolder: "半角数字",
      inputMode: "tel",
      error: isErrorPhoneNumber,
      errorMessage: "電話番号をハイフンなしで正しい形式で入力してください",
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        //半角数字以外拒否
        const replaceValue = e.target.value.replaceAll(/[^0-9]/g, "");
        setPhoneNumber(replaceValue);
      },
      testid: "phoneNumberInput",
    },
  ];

  const { showErrorModal, isVisible, httpMethod, onClose } = useErrorModalStatusContext();

  const resetValues = () => {
    setCompanyName("");
    setEmail("");
    setUserName("");
    setPhoneNumber("");
  };

  const { postEntryParams } = usePostEntryParams();
  const onSubmit = async () => {
    await postEntryParams(companyName, userName, email, phoneNumber).catch((e) => {
      showErrorModal({ httpMethod: "post" });
      throw new Error(e);
    });
    resetValues();
    setSubmitted(true);
  };

  return (
    <Suspense fallback={<FullPageLoading />}>
      {/* ログインしてない場合ErrorModal適用範囲外なため */}
      <ErrorModal httpMethod={httpMethod} isVisible={isVisible} onClose={onClose} />
      <EntryPresenter params={params} onSubmit={onSubmit} disabled={disableSubmit} submitted={submitted} />
    </Suspense>
  );
};

export default EntryContainer;
