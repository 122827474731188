import React from "react";

type State = {
  httpMethod: HttpMethod;
  isVisible: boolean;
  onClose: () => void;
  showErrorModal: ({ httpMethod, onPostClose }: { httpMethod: HttpMethod; onPostClose?: () => void }) => void;
};

const ErrorModalStatusContext = React.createContext({} as State);

export default ErrorModalStatusContext;
