export const OrderStatusFilter = {
  all: "all",
  unanswered: "unanswered",
  uncontracted: "uncontracted",
  notDownloadedBilling: "notDownloadedBilling",
} as const;
export type OrderStatusFilter = typeof OrderStatusFilter[keyof typeof OrderStatusFilter];

export const OrderStatusLabel = {
  [OrderStatusFilter.all]: "すべて",
  [OrderStatusFilter.unanswered]: "見積未返答",
  [OrderStatusFilter.uncontracted]: "契約書未提出",
  [OrderStatusFilter.notDownloadedBilling]: "請求書未確認",
} as const;
