import useUserContext from "hooks/useUserContext";
import BillingListContainer from "pages/Billing/BillingListContainer";
import MonthlyBillingListContainer from "pages/Billing/MonthlyBillingListContainer";

const BillingListController = () => {
  const { isMonthlyBillingAccount } = useUserContext();

  return <>{isMonthlyBillingAccount ? <MonthlyBillingListContainer /> : <BillingListContainer />}</>;
};

export default BillingListController;
