import axios from "axios";
import * as firebaseAuth from "firebase/auth";
import { useEffect, useState } from "react";

import useFirebaseContext from "hooks/useFirebaseContext";

const useFirebaseAuth = () => {
  const { auth } = useFirebaseContext();
  const [currentUser, setCurrentUser] = useState<firebaseAuth.User | null>(null);

  useEffect(() => {
    if (auth?.currentUser) {
      setCurrentUser(auth.currentUser);
    }
  }, [auth]);

  const sendEmailVerification = async () => {
    if (!currentUser) {
      throw new Error("ログインしていません");
    }

    await firebaseAuth.sendEmailVerification(currentUser).catch((e) => {
      console.error(e);
      throw new Error("予期せぬエラーが発生しました。しばらく時間をおいてお試しください");
    });
  };

  const verifyEmailVerificationCode = async (verificationCode: string) => {
    await firebaseAuth.applyActionCode(auth, verificationCode).catch((e) => {
      console.error(e);
      switch (e.code) {
        case "auth/expired-action-code":
          throw new Error("URLの有効期限が切れています。再度メールを送信してください");
        case "auth/invalid-action-code":
          throw new Error("URLが無効です。再度メールを送信してください");
        default:
          throw new Error("予期せぬエラーが発生しました。しばらく時間をおいて最初から実行してください");
      }
    });
  };

  const sendPasswordResetEmail = async (email: string) => {
    await firebaseAuth.sendPasswordResetEmail(auth, email).catch((e) => {
      console.error(e);
      throw new Error("予期せぬエラーが発生しました。しばらく時間をおいてお試しください");
    });

    return true;
  };

  // 二段階認証設定メールのリンクをクリックした際に、二段階認証を無効化する
  // SDKにはAPIがないため、REST APIを叩いている
  const revertSecondFactorAddition = async (apiKey: string, oobCode: string) => {
    // oobCodeの検証
    // resetPasswordのエンドポイントを叩いて、oobCodeが有効かどうかの確認している。
    const verifyCodeResponse = await axios
      .post(
        "https://www.googleapis.com/identitytoolkit/v3/relyingparty/resetPassword",
        { oobCode },
        { params: { key: apiKey } }
      )
      .catch((e) => {
        console.error(e);
        throw new Error("予期せぬエラーが発生しました。しばらく時間をおいてお試しください");
      });

    const phoneInfo = verifyCodeResponse?.data?.mfaInfo?.phoneInfo;
    const email = verifyCodeResponse?.data?.email;

    // MFAの無効化
    await axios
      .post(
        "https://www.googleapis.com/identitytoolkit/v3/relyingparty/setAccountInfo",
        { oobCode },
        { params: { key: apiKey } }
      )
      .catch((e) => {
        console.error(e);
        throw new Error("予期せぬエラーが発生しました。しばらく時間をおいてお試しください");
      });

    return { phoneInfo, email };
  };

  return {
    currentUser,
    sendEmailVerification,
    verifyEmailVerificationCode,
    sendPasswordResetEmail,
    revertSecondFactorAddition,
  };
};

export default useFirebaseAuth;
