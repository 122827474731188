import { Suspense, lazy, useState } from "react";
import { isBrowser, isTablet } from "react-device-detect";
import styled from "styled-components";

import Loading from "components/Loading";
import useCreateOrder from "hooks/api/useCreateOrder";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import useFirebaseStorage from "hooks/useFirebaseStorage";
import { useNoticeModal } from "hooks/useModal";
import useUserContext from "hooks/useUserContext";

const OrderCreateByUploadPresenter = lazy(() =>
  isBrowser
    ? import("pages/OrderCreateByUpload/pc/OrderCreateByUploadPresenter")
    : isTablet
    ? import("pages/OrderCreateByUpload/tb/OrderCreateByUploadPresenter")
    : import("pages/OrderCreateByUpload/sp/OrderCreateByUploadPresenter")
);

const ModalMessageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const OrderCreateByUploadContainer = () => {
  const [step, setStep] = useState(1);
  const [file, setFile] = useState<File>();
  const { uploadOrderFile, validateOrderFile } = useFirebaseStorage();
  const { NoticeModal, showNoticeModal, hideNoticeModal } = useNoticeModal();
  const { orderByFile } = useCreateOrder();
  const { user } = useUserContext();
  const { showErrorModal } = useErrorModalStatusContext();

  const handleSendClick = async () => {
    if (!file || !user) return;

    let error = null;
    const contactId = user.contactId;
    const fileKey = await uploadOrderFile(contactId, file);

    await orderByFile({ filename: file.name, fileKey }).catch((e) => {
      console.error(e);
      error = e;
      showErrorModal({ httpMethod: "post" });
    });

    if (!error) {
      setStep(2);
    }
  };

  const handleFileChange = (file: File) => {
    if (!validateOrderFile(file)) {
      showNoticeModal();
      return;
    }

    setFile(file);
  };

  return (
    <Suspense fallback={<Loading />}>
      <OrderCreateByUploadPresenter
        currentStep={step}
        onSendClick={handleSendClick}
        onChangeFile={handleFileChange}
        onRemoveFile={() => setFile(undefined)}
        file={file}
      />

      <NoticeModal
        title="アップロードできません"
        message={
          <ModalMessageWrap>
            <span>選択したファイルの形式、もしくはサイズが </span>
            <span>非対応のためアップロードできません。</span>
          </ModalMessageWrap>
        }
        onClick={hideNoticeModal}
      />
    </Suspense>
  );
};

export default OrderCreateByUploadContainer;
