import queryString from "query-string";
import { KeyedMutator } from "swr";

import { API_URL } from "constants/api";
import { SortDirection } from "constants/sortDirection";
import { useSwr } from "hooks/useSwr";
import { BillingSortColumn } from "pages/Billing/pc/billingSortColumn";

export type FindBillingsRequestParameter = {
  pageSize: number;
  pageNumber: number;
  sortDirection: SortDirection;
  sortColumn: BillingSortColumn;
  billingYear: string;
  billingMonth: string;
  billingClosingDay: string;
  contactId?: string;
  department?: string;
  searchKeyword?: string;
};

const initialPagination = {
  pageSize: 0,
  pageIndex: 1,
  totalPages: 1,
  totalRecords: 0,
};

const useFindMonthlyBillings = (
  parameter: FindBillingsRequestParameter
): {
  billingList: Billing[] | undefined;
  pagination: Pagination;
  error: Error;
  mutate: KeyedMutator<BillingsResponseBody>;
} => {
  const {
    data: billingsResponseBody,
    error,
    mutate,
  } = useSwr<BillingsResponseBody>(`${API_URL}/monthly-billing?${queryString.stringify(parameter)}`);

  if (!billingsResponseBody)
    return {
      billingList: undefined,
      pagination: initialPagination,
      error,
      mutate,
    };

  return {
    billingList: billingsResponseBody.billings,
    pagination: billingsResponseBody.pagination,
    error,
    mutate,
  };
};

export default useFindMonthlyBillings;
