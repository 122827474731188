import axios from "axios";
import { Auth } from "firebase/auth";
import useSWRMutation from "swr/mutation";

import useFirebaseContext from "hooks/useFirebaseContext";

type AxiosError = {
  error: string;
  message: string;
  statusCode: number;
};

const isDevelopEnv = ["local", "dev", "staging"].includes(process.env.REACT_APP_ENV ?? "");

/**
 * イベント発火時にSWRを使用してAPIを呼び出す
 * @param url: string
 * @param auth: Auth
 * @returns
 */
export const fetcher = async (url: string | null, auth: Auth) => {
  if (!url) return;
  const token = await auth?.currentUser?.getIdToken();

  axios.interceptors.response.use(
    (response) => {
      if (isDevelopEnv) console.log("レスポンスデータ：", response.data);
      return response;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const response = await axios
    .get(url, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .catch((error) => {
      if (isDevelopEnv) console.error("エラー：", error?.response?.data ?? error);
      throw error;
    });

  return response.data;
};

/**
 * useSWRMutationを使用する
 * @param url
 * @returns
 */
export const useSwrMutation = <T>(
  url: string | null
): {
  data: T;
  error: AxiosError;
  isMutating: boolean;
  trigger: (id?: string) => Promise<T>;
} => {
  const { auth } = useFirebaseContext();
  const { data, error, isMutating, trigger } = useSWRMutation([url, auth], ([url, auth]) => fetcher(url, auth));

  return { data, error, isMutating, trigger };
};
