import { useCallback } from "react";

import useClient from "hooks/useClient";

const useCancelOption = (): {
  cancelOption: (optionId: string) => Promise<number>;
} => {
  const { client } = useClient();

  const cancelOption = useCallback(
    async (optionId: string) => {
      const { status } = await client({
        method: "put",
        url: `/option/${optionId}/cancel`,
      });
      return status;
    },
    [client]
  );

  return { cancelOption };
};

export default useCancelOption;
