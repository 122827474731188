import { useState } from "react";
import styled from "styled-components";

import color from "constants/color";

const PopupContainer = styled.div`
  position: relative;
  :hover {
    cursor: pointer;
  }
`;

const PopupBody = styled.div<{ isPopup: boolean; right: string; top: string }>`
  display: ${({ isPopup }) => (isPopup ? "flex" : "none")};
  height: 20px;
  background: ${color.text.link};
  color: ${color.white};
  position: absolute;
  top: -16px;
  width: fit-content;
  white-space: nowrap;
  right: ${({ right }) => right};
  top: ${({ top }) => top};
  padding: 4px 8px 1px 8px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: auto;
  }
`;

const Triangle = styled.div`
  position: absolute;
  top: 24px;
  border-right: 5px solid transparent;
  border-bottom: 8.6025px solid ${color.text.link};
  border-left: 5px solid transparent;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
`;

const Text = styled.p<{ fontSize: string }>`
  font-size: ${({ fontSize }) => fontSize};
`;

// 一行のポップアップのみ対応

const Popup = ({
  children,
  right,
  top,
  fontSize,
  text,
  onClick,
}: {
  children: JSX.Element;
  right: string;
  top: string;
  fontSize: string;
  text: string;
  onClick: () => void;
}): JSX.Element => {
  const [isVisible, setIsVisible] = useState(false);

  const onPopup = () => {
    onClick();
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
    }, 2000);
  };

  return (
    <PopupContainer onClick={onPopup}>
      <PopupBody right={right} top={top} isPopup={isVisible}>
        <Triangle />
        <Text fontSize={fontSize}>{text}</Text>
      </PopupBody>
      {children}
    </PopupContainer>
  );
};

export default Popup;
