/**
 * clipBoardに第一引数の値をコピーするutil関数
 *
 * optionで関数も受け入れる(想定しているのはsetState関数)
 * @param text string
 * @param setState void
 * @returns void
 **/
export const copyOnClipboard = (text: string, setState?: void): void => {
  navigator.clipboard.writeText(text);
  setState;
};
